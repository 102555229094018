import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';


const MenuItems = (props) => {
  if(props.rights){
    return(
      <div className='menuCont'>
        <Link to="/">
              <div className="headTitle clickable">
                <Icon name='home' className="headExit"/>
              </div>
        </Link>
        {props.rights.cleader ? 
          <Link to='/cleader'>
            <div className="headTitle clickable">
              Классное руководство
            </div>
          </Link> : ''}
        {props.rights.teacher ? 
          <Link to='/teacher'>
            <div className="headTitle clickable">
              Результаты
            </div>
          </Link> : ''}
        {props.rights.admin || props.rights.zam || props.rights.director ? 
          <Link to='/cleader'>
            <div className="headTitle clickable">
              Отчеты
            </div>
          </Link> : ''}
        {props.rights.admin || props.rights.zam || props.rights.director ? 
          <Link to='/exams'>
            <div className="headTitle clickable">
              ГИА
            </div>
          </Link> : ''}
      </div>
    )
  }
}  


export default MenuItems;
