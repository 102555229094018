
import '../App.css';

import 'semantic-ui-css/semantic.min.css'
import Head from './headComponents/Head'

const Teacher = (props) => {
    return(
        <div className='app'>
            <Head />
            Учитель
        </div>
    )
  }
  export default Teacher;
  