
import 'semantic-ui-css/semantic.min.css'
import { Icon } from 'semantic-ui-react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import MenuItems from './MenuItems'

const baseUrl = 'https://results.1298.ru/api/'

const Head = (props) => {
    const[userData, setUserData] = useState([])
    
    const logoutFunction = () => {
      window.location.href = '/api/logout'
      return true
    }

    useEffect(() => {
        axios.get(baseUrl + 'user/').then((res) => {
          setUserData(res.data)
        }).catch(function (error) {
            console.error(error)
          if(error.request.status===401){
            window.location.href = 'login/'
          }
        })
      }, [])
    
    const initials = (given_name) => {
        if(given_name){
          let givenNameArr = given_name.split(" ")
          return(givenNameArr[0][0] + "." + givenNameArr[1][0])
        }
        return('')
    }

    return(
        <div className='headBlock'>
            <MenuItems rights={userData.rights} />
            <div className="headButtons">
              <div className='headButtonsSection text clickable'>
                <div className="headUser">{userData.family_name + " " + initials(userData.given_name)}</div>
              </div>
              <div className='headButtonsSection button clickable' onClick={logoutFunction}>
                <Icon name='sign-out'  className="headExit"/>
              </div>
            </div>
        </div>
    )
  }
  export default Head;
  