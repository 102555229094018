
import 'semantic-ui-css/semantic.min.css'
import { Button } from 'semantic-ui-react'

const Login = (props) => {
    const loginFunction = () => {
        window.location.href = '/api/login'
        return true
    }

    return(
        <div className='app'>
            <div className='headBlock'>
                <div className="headTitle">
                    ☰ База результатов
                </div>
                <div className="headButtons">
                    <Button onClick={loginFunction}>Войти через Google</Button>
                </div>
            </div>
        </div>
    )
  }
  export default Login;
  