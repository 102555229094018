
import '../../App.css';
import 'semantic-ui-css/semantic.min.css'

const Footer = (props) => {
    return(
        <div className='Footer'>
            Низ
        </div>
    )
  }
  export default Footer;
  