
import '../App.css';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css'
import Head from './headComponents/Head'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';




const baseUrl = 'https://results.1298.ru/api/'



const Exams = () => {
    const[classes, setClasses] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(baseUrl + 'student/classes').then((res) => {
            setClasses(res.data)
        }).catch(function (error) {
            console.error(error)
          if(error.request.status===401){
            window.location.href = 'login/'
          }
        })
    }, [])

    return(
        <div className='app'>
            <Head />
            <div className='pageTitle'>Предметы ГИА</div>
            <div className="gridClasses">
                {classes.map((el) => {
                    return(
                        <div className="clickable" key={classes.indexOf(el)} onClick={() => navigate(el.className)}>
                            {el.className}
                        </div>
                    )
                })}
            </div>
        </div>
    )
  }
  export default Exams;
  