import 'semantic-ui-css/semantic.min.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Homepage from './components/Homepage';
import Teacher from './components/Teacher';
import Cleader from './components/Cleader';
import Exams from './components/Exams';
import ExamClass from './components/ExamClass';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/cleader" element={<Cleader />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/login" element={<Login />} />
        <Route path="/exams" element={<Exams />} />
        <Route path="/exams/:cl" element={<ExamClass />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)