
import '../App.css';

import 'semantic-ui-css/semantic.min.css'
import Head from './headComponents/Head'

const Homepage = (props) => {
    return(
        <div className='app'>
            <Head />
            Домашняя страница
        </div>
    )
  }
  export default Homepage;
  