
import '../App.css';
import 'semantic-ui-css/semantic.min.css';
import Head from './headComponents/Head';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Icon, Table } from 'semantic-ui-react'
import Footer from './headComponents/Footer';

const baseUrl = 'https://results.1298.ru/api/'

const ExamClass = (props) => {
    const[students, setStudents] = useState([])
    const {cl} = useParams()

    useEffect(() => {
        axios.get(baseUrl + 'student/search/' + cl).then((res) => {
            setStudents(res.data)
        }).catch(function (error) {
            console.error(error)
          if(error.request.status===401){
            window.location.href = 'login/'
          }
        })
    }, [cl])

    const predmetOutput = (element) => {
        return('')
    }
    
    return(
        <div className='app'>
            <Head />
            <div className='pageTitle'>Предметы ГИА - {cl}</div>
            <div className='studentsTable'>
                <Table celled structured className=''>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>Фамилия Имя Отчество</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>РУС</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>МАТ</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>АНГ</Table.HeaderCell>
                            <Table.HeaderCell rowSpan='2'>ЛИТ</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell >Проф</Table.HeaderCell>
                            <Table.HeaderCell >Баз</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>  
                    <Table.Body>
                        {students.map((el) => {
                            return(
                                <Table.Row key={el.aisId}>
                                    <Table.Cell className="">
                                        {el.fio}
                                    </Table.Cell>
                                    <Table.Cell onClick={() => console.log(el.aisId, el.fio, 'rus')}>
                                        <Icon color='green' name='checkmark' size='large' />
                                    </Table.Cell>
                                    <Table.Cell onClick={() => console.log(el.aisId, el.fio, 'mat_prof')}>
                                        <Icon color='green' name='checkmark' size='large' />
                                    </Table.Cell>
                                    <Table.Cell onClick={() => console.log(el.aisId, el.fio, 'mat_base')}>
                                        <Icon color='green' name='checkmark' size='large' />
                                    </Table.Cell>
                                    <Table.Cell onClick={() => console.log(el.aisId, el.fio, 'eng')}>
                                        <Icon color='green' name='checkmark' size='large' />
                                    </Table.Cell>
                                    <Table.Cell onClick={() => console.log(el.aisId, el.fio, 'lit')}>
                                        <Icon color='green' name='checkmark' size='large' />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
            <Footer />
        </div>
    )
  }
  export default ExamClass;
  