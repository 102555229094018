
import '../App.css';

import 'semantic-ui-css/semantic.min.css'
import Head from './headComponents/Head'

const Cleader = (props) => {
    return(
        <div className='app'>
            <Head />
            Классный руководитель
        </div>
    )
  }
  export default Cleader;
  